import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import {useEffect, useState} from "react";
import {Wallet_model} from './Metamask';

export const Navigation = (props) => {

  const {web3Loading, getweb3} = Wallet_model();
  const [myWeb3, setMyWeb3] = useState();
  let walletConnected = false;
  async function connectWallet() {
    await getweb3().then(response => {
      setMyWeb3(response);
      response.eth.getAccounts().then(result => {
        walletConnected = true;
        console.log (result)
        console.log("connected")
      }, e => { 
        console.error("failed to connect")
        walletConnected = false;
      })
    });
  };

  useEffect(() => {
    // connectWallet()
  }, []);
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='#page-top'>
            Rooster Fighters
          </a>{' '}
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >

          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href={props.data ? props.data.twitter : '/'} rel="noopener noreferrer" className="link page-scroll" target="_blank">
                <FontAwesomeIcon icon={faTwitter}  className="link-svg"/>

              </a>
            </li>
            <li>
              <a href={props.data ? props.data.instagram : '/'} rel="noopener noreferrer" className="link" target="_blank">
                <FontAwesomeIcon icon={faInstagram}  className="link-svg"/>

              </a>
            </li>
            <li>
              <a href={props.data ? props.data.discord : '/'} rel="noopener noreferrer" className="link" target="_blank">
                  <FontAwesomeIcon icon={faDiscord} className="link-svg"/>
                </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                About us
              </a>
            </li>
            <li>
              <a href='#arena' className='page-scroll'>
                The Arena
              </a>
            </li>
            <li>
              <a href='#roadmap' className='page-scroll'>
                Roadmap
              </a>
            </li>
            {/* <li>
              {web3Loading? <button className="btn-metamask page-scroll" disabled> Loading ... </button>: (web3Loading === false && walletConnected === true) ? <button className="btn-metamask page-scroll" onClick={connectWallet}>Connect Wallet</button> : <button className="btn-metamask page-scroll" disabled> Connected </button>}
            </li> */}
            <li>
              <a href='#team' className='page-scroll'>
                Team
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
