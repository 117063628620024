import React from 'react'

export const Contact = (props) => {

  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; 2021 Rooster Fighters Design with{' '}
            <a href='https://discord.gg/heHzAJGre2' rel='nofollow' className="love-link">
              Love
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
