import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import {RoadmapComponent} from "./roadmapcomp";
import * as dataJSON from './../data/data.json';
import handleViewport from 'react-in-viewport';


const lenRoadmap = () => {
  const arr = []
  for (let index = 0; index < dataJSON.RoadmapComponents.length; index++) 
  {
    arr.push(dataJSON.RoadmapComponents[index])
  }
  return arr
}

export const Roadmap = (props) => {
  AOS.init()
  useEffect(() => {
    if (window.innerWidth >= 1100) {
    document.querySelector('#svg-road').setAttribute("height", window.innerHeight);
    document.querySelector('#svg-road').setAttribute("width", window.innerWidth);
    
  let path = document.querySelector('#line-roadmap');
    // Get length of path... ~577px in this case
    let pathLength = path.getTotalLength();
    
    // Make very long dashes (the length of the path itself)
    path.style.strokeDasharray = pathLength + ' ' + pathLength;
    
    // Offset the dashes so the it appears hidden entirely
    path.style.strokeDashoffset = pathLength;
    
    path.getBoundingClientRect();
    window.addEventListener("scroll", function(e) {
      if (path.getBoundingClientRect().top - this.window.innerHeight < 0 ) {

        // Had to try three or four differnet methods here. Kind of a cross-browser nightmare.
        var scrollPercentage = 2.5*(-path.getBoundingClientRect().top + this.window.innerHeight) / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
        // Length to offset the dashes
        var drawLength = pathLength * scrollPercentage;
        // Draw in reverse
        path.style.strokeDashoffset = pathLength - drawLength;
        
        // When complete, remove the dash array, otherwise shape isn't quite sharp
        // Accounts for fuzzy math
        if (scrollPercentage >= 0.99) {
          path.style.strokeDasharray = "none";
          
        } else {
          path.style.strokeDasharray = pathLength + ' ' + pathLength;
        }
      }
    
      
    });}
    
  }, []);


  return (
    <div>
      
      <div className="roadmap-container">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" id="svg-road" className="el-roadmap">
          <line x1="50%" y1="0" x2="50%" y2="90" stroke="black" strokeWidth="1" id="line-roadmap" />
        </svg>
        <div>
      {lenRoadmap().length > 0 ? lenRoadmap().map((d,i) => {
        return (
        <RoadmapComponent data={d} key={i} index={i} className="p-roadmap"/>
        )
        }): "loading"}
        </div>
        </div>
    </div>
  )
}

const ViewportBlock = handleViewport(Roadmap, /** options: {}, config: {} **/);


export const Comp = (props) => {
  return (
    <div>
      
      <ViewportBlock onEnterViewport={() => console.log('in view')}/>
    </div>
  )
}