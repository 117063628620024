import React from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

export const RoadmapComponent = (props) => {
  AOS.init()
  if (props.index % 2 === 0) {

    return (
      <div className="margin-card">
        <div className="" data-aos="fade-right" data-aos-duration="1000" data-aos-delay={200*props.index}>
              <div className="p-left about-text roadmap-text" data-aos="fade-left" data-aos-duration="2000">
                <h2 className="font-small">{props.data ? props.data.title: "loading..."}</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
          </div>
        </div>
      </div>
    );
  } else {

    return (
      <div className="margin-card">
      <div className="" data-aos="fade-right" data-aos-duration="1000" data-aos-delay={200*props.index}>
            <div className="p-right about-text roadmap-text" data-aos="fade-left" data-aos-duration="2000">
              <h2 className="font-small">{props.data ? props.data.title: "loading..."}</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
        </div>
      </div>
    </div>
  );
  }
};
