import React from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
export const About = (props) => {
  AOS.init()
  return (
    
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6" data-aos="fade-right">
            {" "}
            <img src="https://firebasestorage.googleapis.com/v0/b/roosterfighters-7cac7.appspot.com/o/GIF_NFT.gif?alt=media&token=9a0b2341-7eb7-4c66-b9a6-7d6807b0212a" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6" data-aos="fade-left">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
