import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import { Deck } from "./components/deck2";
import 'aos/dist/aos.css';
import { Comp} from './components/Roadmap';

import "./App.css";
import "./components/deck.css";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);


  
  


  return (
    <div>
      <div className="d-container">
        <div className="deck-container">
          <h1 className="title-deck">Generate and Fight with your Rooster</h1>
          <Deck/>
          <p className="text-deck">9999 unique roosters with hand drawn custom attributes</p>
          <a className="btn" href="https://discord.gg/WvTSMYD84N" rel="noopener noreferrer" target="_blank">Join us on Discord</a>
          </div>
      </div>
      <Navigation data={landingPageData.Contact}/>
      <About data={landingPageData.About} />
      <Features data={landingPageData.Features} />
      <div className='container ta-roadmap' id="roadmap">
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2>The Roadmap</h2>
        </div>
        </div>
      <Comp/>
      {/* <Services data={landingPageData.Services} /> */}
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact}  />
    </div>
  );
};

export default App;
