import React, { useState } from 'react'
import { useSprings, animated, interpolate } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import './deck.css'

const cards = [
  'https://firebasestorage.googleapis.com/v0/b/roosterfighters-7cac7.appspot.com/o/IMG_6561.jpg?alt=media&token=bc2cc915-9293-485e-9480-f5a17425b68a',
  'https://firebasestorage.googleapis.com/v0/b/roosterfighters-7cac7.appspot.com/o/IMG_6580.jpg?alt=media&token=76a5b27a-f8ae-4cc4-8541-e57d2a8d11e9',
  'https://firebasestorage.googleapis.com/v0/b/roosterfighters-7cac7.appspot.com/o/NFT_3.jpg?alt=media&token=9042aa4b-82de-4d2e-9b97-96fc0ce55f28',
  'https://firebasestorage.googleapis.com/v0/b/roosterfighters-7cac7.appspot.com/o/teache.png?alt=media&token=3c2678da-9f9a-463b-8185-cb5ca932751f',
  'https://firebasestorage.googleapis.com/v0/b/roosterfighters-7cac7.appspot.com/o/robot.png?alt=media&token=d4b6acc8-6d4b-492a-8998-1e4d50de523e'
]


// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = i => ({ x: 0, y: i * -4, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 })
const from = i => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) => `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`

export const Deck = () => {
    const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out
    const [props, set] = useSprings(cards.length, i => ({ ...to(i), from: from(i) })) // Create a bunch of springs using the helpers above
    // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
    const bind = useGesture(({ args: [index], down, delta: [xDelta], distance, direction: [xDir], velocity }) => {
        const trigger = velocity > 0.2 // If you flick hard enough it should trigger the card to fly out
        const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right
        if (!down && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
        set(i => {
        if (index !== i) return // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index)
        const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0) // How much the card tilts, flicking it harder makes it rotate faster
        const scale = down ? 1.1 : 1 // Active cards lift up a bit
        return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } }
        })
        if (!down && gone.size === cards.length) setTimeout(() => gone.clear() || set(i => to(i)), 600)
    })
    // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
    return (
        props.map(({ x, y, rot, scale }, i) => (
            // `url("https://media.discordapp.net/attachments/795564840496005141/911276461342724096/IMG_6581.png")`
        <animated.div key={i} style={{ transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`) }} >
            {/* This is the card itself, we're binding our gesture to it (and inject its index so we know which is which) */}
            <animated.div className="image" {...bind(i)} style={{ transform: interpolate([rot, scale], trans), backgroundImage: `url(${cards[i]})` }} /> 
        </animated.div>
    ))
    )

}